// require("@rails/ujs").start()
// require("turbolinks").start()
// require("channels")
// require("stimulus")

// import $ from 'jquery'
// import bootstrap from "bootstrap/dist/js/bootstrap.bundle"

// window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

// document.addEventListener("turbolinks:load", function() {
//     $('[data-bs-toggle="tooltip"]').tooltip()
//     $('[data-toggle="popover"]').popover()
// })

// import './controllers'

// import "@hotwired/turbo-rails"

import { Application } from "@hotwired/stimulus"

import Sortable from 'stimulus-sortable'

const application = Application.start()

application.register('sortable', Sortable)



console.log('applicantion.js')
